import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-crud-react-v2/phpreaction-frontend-crud-react-v2/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-crud-react-v2/phpreaction-frontend-crud-react-v2/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/work/phpreaction-frontend-crud-react-v2/phpreaction-frontend-crud-react-v2/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-crud-react-v2/phpreaction-frontend-crud-react-v2/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/home/runner/work/phpreaction-frontend-crud-react-v2/phpreaction-frontend-crud-react-v2/src/components/common/ThemeProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/phpreaction-frontend-crud-react-v2/phpreaction-frontend-crud-react-v2/src/store/StoreProvider.tsx");
